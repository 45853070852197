@import url(./assets/fonts/fonts.css);
@import "~quill/dist/quill.snow.css";

* {
  box-sizing: border-box;
  /* font-family: 'Cerebri Sans Pro'; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  white-space: pre-line;
}

textarea {
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #29373f;
  padding: 16px;
}

input.Mui-disabled {
  background-color: #EEE !important;
  color: #667085 !important;
}

.tooltip_div {
  padding: 10px;
  background-color: rebeccapurple;
}

/* .apexcharts-graphical {
  transform: translate(-65px, 28px) !important;
} */