@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts/cerebri/CerebriSansPro-Thin.woff2') format('woff2'),
      url('../fonts/cerebri/CerebriSansPro-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts/cerebri/CerebriSansPro-ExtraLight.woff2') format('woff2'),
      url('../fonts/cerebri/CerebriSansPro-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts/cerebri/CerebriSansPro-Light.woff2') format('woff2'),
      url('../fonts/cerebri/CerebriSansPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts/cerebri/CerebriSansPro-Regular.woff2') format('woff2'),
      url('../fonts/cerebri/CerebriSansPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts/cerebri/CerebriSansPro-Medium.woff2') format('woff2'),
      url('../fonts/cerebri/CerebriSansPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts/cerebri/CerebriSansPro-SemiBold.woff2') format('woff2'),
      url('../fonts/cerebri/CerebriSansPro-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts/cerebri/CerebriSansPro-Bold.woff2') format('woff2'),
      url('../fonts/cerebri/CerebriSansPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts/cerebri/CerebriSansPro-ExtraBold.woff2') format('woff2'),
      url('../fonts/cerebri/CerebriSansPro-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts/cerebri/CerebriSansPro-Heavy.woff2') format('woff2'),
      url('../fonts/cerebri/CerebriSansPro-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}



/* @font-face {
  font-family: 'inter black';
  src: local('inter_black'), url(./inter/inter_black.ttf) format('truetype');
}

@font-face {
  font-family: 'inter light';
  src: local('inter_light'), url(./inter/inter_light.ttf) format('truetype');
}

@font-face {
  font-family: 'inter bold';
  src: local('inter_bold'), url(./inter/inter_bold.ttf) format('truetype');
}

@font-face {
  font-family: 'inter semibold';
  src: local('inter_semibold slnt=0'),
    url('./inter/inter_semibold slnt=0.ttf') format('truetype');
}

@font-face {
  font-family: 'inter regular';
  src: local('inter_regular'),
    url(./inter/inter_regular\ slnt=0.ttf) format('truetype');
} */
